@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

body, p, span, button, .lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

strong, h1, h2, h3, h4, h5, h6, .lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

option {
  color: black;
}

.landing-page {
  position: relative;
}

.landing-page .chakra-container {
  max-width: 1000px;
}

.chakra-container {
  max-width: 1000px !important;
}

#header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 15px;
  background-color: transparent; /* Initial background color */
  transition: background-color 0.3s ease; /* Smooth transition */
  z-index: 1000; /* Ensure header is on top */
}

#header.scrolled {
  background-color: white; /* Color when scrolled */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
}

.logo-text {
  color: #3F37C9 !important;
  font-size: 30px;
  font-weight: 900;
  font-family: "Oswald", sans-serif;
  position: relative;
  text-align: center;
  animation: blink 1s infinite alternate; /* Blinking effect */
  text-transform: uppercase;
  font-family: Arial, sans-serif;
}

/* 3D Effect */
.logo-text::before {
  content: "LogoHouse"; /* Add the same text */
  position: absolute;
  top: 5px; /* Adjust for 3D shadow depth */
  left: 5px;
  color: #3498db; /* Shadow color */
  z-index: -1;
}

/* Blinking Keyframes */
@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0.8; }
}

.banner-container {
  height: 500px;
  position: relative;
  background: 
  linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.1)), /* Black overlay with 50% opacity */
  url('../../assets/img/banner.jpg'); /* Add your image path here */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0px;
}

.banner-bg-img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  opacity: 0.3;
}

p.chakra-text.banner-small-description {
  color: white;
  font-size: 26px;
  text-align: center;
  text-shadow: 2px 2px 2px #3F37C9;
  margin-bottom: 10px;
}

.banner-container h2 {
  color: white;
  font-size: 55px;
  letter-spacing: 4px;
  text-align: center;
  margin-bottom: 35px;
  text-shadow: 2px 2px 5px #3F37C9;
}

p.chakra-text.banner-description {
  color: white;
  font-size: 22px;
  text-align: center;
  text-shadow: 2px 2px 1px #0d076e;
  margin-bottom: 35px;
}

.banner-container input {
  text-align: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.95);
  margin-right: 15px;
  min-width: 450px;
}

.cart-item-checkbox {
  user-select: none;
}

.cart-item-checkbox .chakra-checkbox__control {
  position: absolute;
  top: 15px;
  right: 15px;
  user-select: none;
}


.chakra-button, .btn-grad {
  background-image: linear-gradient(to right, #3F37C9 0%, #480CA8  51%, #7209B7  100%) !important;
}

.chakra-button, .btn-grad {
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;
  box-shadow: 0 0 20px #eee !important;
  min-width: 110px !important;
  padding: 0 30px !important;
}

.chakra-button:hover, .btn-grad:hover {
  background-position: right center !important; /* change the direction of the change here */
  color: #fff !important;
  text-decoration: none !important;
}

.landing-page-title {
 color: #3F37C9 !important;
 font-size: 38px;
 position: relative;
 text-align: center;
 font-weight: 600;
 text-shadow: 5px 5px #c5c2e4;
 margin: 30px;
}

.chakra-text.landing-page-description {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
}

.about-us-container {
  padding: 60px 0px;
}

hr.center-diamond {
  border: 0;
  height: 1px;
  width: 30%;
  position: relative;
  margin: 30px auto;
  background: #3F37C9;
}

hr.center-diamond::before{
  content: "";
  width: 6px;
  height: 6px;
  background: #3F37C9;
  display: inline-block;
  border: 2px solid #3F37C9;
  position: absolute;
  top: -5px;
  left: 50%;
  margin: 0 0 0 -3px;
  transform:rotate(45deg);
  -ms-transform:rotate(45deg); /* IE 9 */
  -webkit-transform:rotate(45deg); /* Opera, Chrome, and Safari */
}

p.chakra-text.benefit-title {
  font-weight: 700;
  font-size: 18px;
  color: #3e37c9;
  margin-bottom: 5px;
  text-align: center;
}

.benefit-container {
  padding: 10px 0px 60px;
}

.join-us-container {
  background-image: linear-gradient(to right, #3F37C9 , #3a0ca3);
  padding: 35px 0px;
}

.join-us-title {
  color: white !important;
  font-size: 32px;
  position: relative;
  font-weight: 600;
}

.join-us-container .landing-page-description {
  color: white;
}

.join-us-container .chakra-container {
  max-width: 750px !important;
}

.join-us-container button {
  background-image: linear-gradient(to right, #3F37C9 0%, #480CA8  51%, #7209B7  100%) !important;
  transition: 0.5s !important;
  background-size: 200% auto !important;
  color: white !important;
  min-width: 110px !important;
  padding: 0 30px !important;
  border: 1px solid white !important;
}

.join-us-container button:hover {
   background-position: right center !important; /* change the direction of the change here */
   color: #fff !important;
   text-decoration: none !important;
 }

@media only screen and (max-width: 767px) {
  .banner-container input {
    min-width: unset;
  }
}